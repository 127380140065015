import styled from "styled-components";
import { ContainerStyle } from "../../style/Common.style";

export const AgreementStyle = styled.main`
  .header {
    height: 20vh;
  }

  .signature {
    display: flex;
    align-items: flex-end;
  }

  .section-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;

    @media ( max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  .agreement-wrapper {
    .section-wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
      grid-template-columns: 1fr;
    }

    .agreement-wrapper {
      max-height: 50vh;
    }
  }

  .final-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .form-wrapper {
    ${ContainerStyle};

    padding: 20px;
    background-color: #f9f9f9;
    margin-top: 20px;

    .form-inputs {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .input-field {
        width: 100%;
      }
    }

    .agreement {
      margin-bottom: 20px;
      max-width: 350px;
    }
  }

  .agreement-wrapper {
    max-height: 70vh;
    overflow-y: auto;
    border: 1px solid #e5e5e5;
    margin: 20px;
    padding: 20px;

    @media ( max-width: 900px ) {
      max-height: 27vh;
    }
  }

  .reset-btn {
    background: none;
    border: none;
  }

  .reset-btn:hover {
    cursor: pointer;
  }

  .reset {
    width: 22px;
    margin-left: 8px;
  }
`;
