import styled from "styled-components";

export const PaymentDetailsStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 20px;
  background-color: #e1e9ef;
  margin-top: 20px;

  input {
    text-align: right;
    color: #19222b;
    font-weight: bold;
    font-size: 16px;

    &:disabled {
      background-color: #e1e9ef;
      color: #19222b;
      font-weight: bold;
      font-size: 16px;
      border: 0;
    }
  }

  .total-amount {
    padding-right: 25px;
  }

  .fields-wrapper {
    display: flex !important;
    flex-direction: column !important;

    .label-with-children-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: right;
      gap: 20px;
      width: 100%;

      label {
        color: #696a6d;
      }

      .field-label {
        display: none;
      }

      .content {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
`;

// `
// <table
//       style="
//         width: 600px;
//         border: 1px solid #e2e6ec;
//         margin: auto;
//         font-family: Poppins;
//         box-sizing: border-box;
//         border-collapse: collapse;
//       "
//     >
//       <colgroup>
//         <col style="width: 100px" />
//         <col style="width: 100px" />
//         <col style="width: 100px" />
//         <col style="width: 100px" />
//         <col style="width: 100px" />
//         <col style="width: 100px" />
//       </colgroup>
//       <thead>
//         <tr style="background-color: #f9f2ea">
//           <td colspan="2" style="padding-left: 40px">
//             <img src="/images/logo.png" alt="" />
//           </td>
//           <td colspan="2" style="text-align: center">
//             <img src="https://isaahtrucking.com/php/banner-image.png" alt="" />
//           </td>
//           <td colspan="2" style="padding-right: 45px; text-align: right">
//             <h1
//               style="
//                 font-size: 28px;
//                 font-weight: 800;
//                 color: #cc240d;
//                 margin: 0;
//                 line-height: 28px;
//               "
//             >
//               INVOICE
//             </h1>
//             <p style="font-size: 12px; color: #5e6470; margin: 0">
//               <span>#ITL1234</span> <br />
//               <span> ' . $pickUpDate . '</span>
//             </p>
//           </td>
//         </tr>
//       </thead>
//       <tbody>
//         <tr style="border-bottom: 0.5px solid #d7dae0">
//           <td
//             colspan="2"
//             style="padding-left: 15px; padding-bottom: 16px; padding-top: 16px"
//           >
//             <p style="font-size: 12px; color: #5e6470; margin: 0">Bill From</p>
//             <h2
//               style="
//                 font-size: 18px;
//                 font-weight: 600;
//                 color: #c81908;
//                 margin: 0;
//                 width: 175px;
//               "
//             >
//               Isaah Trucking Ltd
//             </h2>
//           </td>
//           <td colspan="2" style="padding: 16px 0px 16px 10px">
//             <p
//               style="
//                 font-size: 12px;
//                 color: #5e6470;
//                 margin: 0;
//                 border-left: 1px solid #d7dae0;
//                 padding-left: 15px;
//               "
//             >
//               Representative
//             </p>
//             <p
//               style="
//                 font-size: 12px;
//                 margin: 0;
//                 border-left: 1px solid #d7dae0;
//                 padding-left: 15px;
//               "
//             >
//               <span style="color: #1a1c21; font-weight: 600">
//               ' . $representativeName . '
//                 </span>

//               <span style="color: #5e6470">
//               ' . $representativePhone . '
//               </span>
//             </p>
//           </td>
//           <td
//             colspan="2"
//             style="
//               text-align: right;
//               padding-right: 15px;
//               padding-bottom: 16px;
//               padding-top: 16px;
//             "
//           >
//             <p style="font-size: 12px; color: #5e6470; margin: 0">Bill To</p>
//             <p style="color: #1a1c21; font-weight: 600; margin: 0">
//             ' . $billToName . '
//             </p>
//           </td>
//         </tr>
//         <tr style="vertical-align: top">
//           <td colspan="3" style="padding: 20px 6px 20px 12px">
//             <p
//               style="
//                 background-color: #f0f5f5;
//                 display: block;
//                 padding: 10px;
//                 border-radius: 6px;
//                 font-size: 12px;
//                 margin: 0;
//                 height: 130px;
//               "
//             >
//               <span style="font-weight: 700; color: #1a1c21; width: 100%">
//                 <span>Pickup</span>
//                 <span
//                   style="
//                     float: right;
//                     clear: both;
//                     color: #5e6470;
//                     font-weight: 400;
//                   "
//                   >
//                   ' . $billFromPhone . '
//                     </span
//                 >
//               </span>
//               <br />
//               <span
//                 style="
//                   color: #1a1c21;
//                   font-weight: 600;
//                   display: inline-block;
//                   margin-top: 4px;
//                 "
//                 >
//                 ' . $billFromName . '
//                 </span>
//               <br />
//               <span
//                 style="color: #5e6470; display: inline-block; margin-top: 4px"
//                 >
//                 ' . $pickupFrom . '
//                 </span>
//               <br />
//               <span
//                 style="
//                   color: #5e6470;
//                   display: inline-block;
//                   margin-top: 6px;
//                   line-height: 1.25;
//                 "
//                 >' .$pickupFullAddress. '</span
//               >
//             </p>
//           </td>
//           <td colspan="3" style="padding: 20px 12px 20px 6px">
//             <p
//               style="
//                 background-color: #f0f5f5;
//                 display: block;
//                 padding: 10px;
//                 border-radius: 6px;
//                 font-size: 12px;
//                 margin: 0;
//                 height: 130px;
//               "
//             >
//               <span style="font-weight: 700; color: #1a1c21">
//                 <span>Dropoff</span>
//                 <span
//                   style="
//                     float: right;
//                     clear: both;
//                     color: #5e6470;
//                     font-weight: 400;
//                   "
//                   >
//                   ' .$dropOffPersonPhone. '
//                   </span
//                 >
//               </span>
//               <br />
//               <span
//                 style="
//                   color: #1a1c21;
//                   font-weight: 600;
//                   display: inline-block;
//                   margin-top: 4px;
//                 "
//                 >
//                 ' .$dropOffPersonName. '
//                 </span
//               >
//               <br />
//               <span
//                 style="color: #5e6470; display: inline-block; margin-top: 4px"
//                 >
//                 ' .$deliveryTo. '
//                 </span
//               >
//               <br />
//               <span
//                 style="
//                   color: #5e6470;
//                   display: inline-block;
//                   margin-top: 6px;
//                   line-height: 1.25;
//                 "
//                 >
//                 ' .$dropOffFullAddress. '
//                 </span>
//             </p>
//           </td>
//         </tr>
//         <tr
//           style="
//             border-bottom: 0.5px solid #d7dae0;
//             border-top: 0.5px solid #d7dae0;
//           "
//         >
//           <td colspan="3" style="padding: 12px 0px 12px 12px">
//             <p style="margin: 0; font-size: 12px">
//               <span style="font-weight: 600; color: #1a1c21"
//                 >Shipment Date</span
//               >
//               <span style="color: #5e6470; padding-left: 10px">Morning</span>
//               <span style="color: #5e6470; padding: 0px 8px">|</span>
//               <span style="color: #5e6470">
//               ' .$pickUpDate. '
//               </span>
//             </p>
//           </td>
//           <td
//             colspan="3"
//             style="text-align: right; padding: 12px 12px 12px 0px"
//           >
//             <p style="margin: 0; font-size: 12px">
//               <span style="font-weight: 600; color: #1a1c21">ETA Delivery</span>
//               <span style="color: #5e6470">4 to 5 days</span>
//             </p>
//           </td>
//         </tr>
//         <tr style="width: 576px; margin: 12px">
//           <td colspan="6" style="padding: 12px">
//             <table style="border-collapse: collapse; width: 100%">
//               <tr
//                 style="
//                   color: #fff;
//                   font-size: 12px;
//                   background-color: #cc240d;
//                   border: 0.5px solid #cc240d;
//                 "
//               >
//                 <th
//                   style="
//                     font-weight: 600;
//                     padding: 7px 20px;
//                     text-align: left;
//                     width: 312px;
//                   "
//                 >
//                   Vehicle Details
//                 </th>
//                 <th
//                   style="font-weight: 600; padding: 7px 0px; text-align: right"
//                 >
//                   Qty
//                 </th>
//                 <th
//                   style="
//                     font-weight: 600;
//                     padding: 7px 20px;
//                     text-align: right;
//                     width: 70px;
//                   "
//                 >
//                   Price
//                 </th>
//               </tr>

//               ' . $vehiclesTable . '

//               <tr>
//                 <td colspan="6">
//                   <table style="border-collapse: collapse; width: 100%">
//                     <colgroup>
//                       <col style="width: 325px" />
//                       <col style="width: 110px" />
//                       <col style="width: 110px" />
//                     </colgroup>
//                     <tr>
//                       <td colspan="1"></td>
//                       <td
//                         colspan="1"
//                         style="
//                           font-size: 12px;
//                           color: #5e6470;
//                           padding: 10px 20px 7px 20px;
//                           border-bottom: 0.5px solid #d7dae0;
//                         "
//                       >
//                         Total:
//                       </td>
//                       <td
//                         colspan="1"
//                         style="
//                           text-align: right;
//                           font-size: 12px;
//                           color: #1a1c21;
//                           font-weight: 600;
//                           padding: 10px 20px 7px 20px;
//                           border-bottom: 0.5px solid #d7dae0;
//                         "
//                       >
//                         $' . $totalAmount . '
//                       </td>
//                     </tr>
//                     <tr>
//                       <td colspan="1"></td>
//                       <td
//                         colspan="1"
//                         style="
//                           font-size: 12px;
//                           color: #5e6470;
//                           padding: 7px 20px;
//                         "
//                       >
//                         Deposit
//                       </td>
//                       <td
//                         colspan="1"
//                         style="
//                           text-align: right;
//                           font-size: 12px;
//                           color: #1a1c21;
//                           font-weight: 600;
//                           padding: 7px 20px;
//                         "
//                       >
//                         $' . $deposit . '
//                       </td>
//                     </tr>
//                     <tr
//                       style="font-size: 12px; font-weight: 600; color: #cc240d"
//                     >
//                       <td colspan="1"></td>
//                       <td
//                         colspan="1"
//                         style="
//                           padding: 7px 20px;
//                           border-top: 2px solid #cc240d;
//                           border-bottom: 2px solid #cc240d;
//                         "
//                       >
//                         Balance
//                       </td>
//                       <td
//                         colspan="1"
//                         style="
//                           padding: 7px 20px;
//                           text-align: right;
//                           border-top: 2px solid #cc240d;
//                           border-bottom: 2px solid #cc240d;
//                         "
//                       >
//                         $' . $balance . '
//                       </td>
//                     </tr>
//                   </table>
//                 </td>
//               </tr>
//             </table>
//           </td>
//         </tr>
//         <tr style="height: 100px; background-color: #1a1c21"></tr>
//       </tbody>
//       <tfoot>
//         <tr
//           style="
//             font-size: 12px;
//             color: #696a6d;
//             border-top: 0.5px solid #d7dae0;
//           "
//         >
//           <td colspan="6" style="padding-top: 10px; padding-left: 12px">
//             (610) 785-6302
//           </td>
//         </tr>
//         <tr style="font-size: 12px; color: #696a6d">
//           <td colspan="6" style="padding-left: 12px">
//             USDOT: 4112838 <span style="margin: 0px 15px">|</span> MC: 1606586
//           </td>
//         </tr>
//         <tr style="font-size: 12px; color: #696a6d">
//           <td colspan="6" style="padding-left: 12px">
//             <a
//               href="https://isaahtrucking.com/"
//               style="text-decoration: none; color: #cc240d; font-weight: 600"
//               >www.isaahtrucking.com</a
//             >
//           </td>
//         </tr>
//         <tr style="font-size: 12px; color: #696a6d">
//           <td colspan="6" style="padding-left: 12px; padding-bottom: 12px">
//             625 Lancaster Ave, Wayne, PA 19087
//           </td>
//         </tr>
//       </tfoot>
//     </table>
// `
