import { useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { FormProvider, FieldValues, useFormContext } from "react-hook-form";
import { message } from "antd";
import {
  Button,
  CheckboxField,
  InputField,
  PhoneInputField,
} from "../../components/common";
import { ButtonType } from "../../components/common/Button/Button";
import { Link } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import InputWrapper from "../../components/common/InputField/InputWrapper";

const AgreementForm = ({
  setIsSubmitted,
}: {
  setIsSubmitted: (_: boolean) => void;
}) => {
  const methods = useForm();
  const { handleSubmit, reset } = methods;
  const signatureCanvasRef = useRef<any>(null);

  const handleFormSubmit = async (values: FieldValues) => {
    // Check if the signature canvas is valid
    if (!signatureCanvasRef.current || signatureCanvasRef.current.isEmpty()) {
      message.error("Please provide your signature.");
      return;
    }

    const signatureImage = signatureCanvasRef.current.toDataURL("image/png");

    try {
      const response = await axios.post(
        `https://isaahtrucking.com/php/agreement.php?name=${values.name}&email=${values.email}&phone=${values.phone}&signature=${signatureImage}`
      );

      message.success("Email sent successfully");
      setIsSubmitted(true);
      reset();
    } catch (error) {
      console.error("Error submitting form", error);
      message.error("Failed to submit form. Please try again.");
    }
  };

  const clearSignature = () => {
    if (signatureCanvasRef.current) {
      signatureCanvasRef.current.clear();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="form-inputs">
          <InputField
            className="input-field"
            label="Your Name"
            name="name"
            rules={{ required: "This field is required" }}
          />

          <PhoneInputField
            className="input-field"
            label="Phone Number"
            name="phone"
            inputProps={{ type: "tel" }}
            rules={{ required: "This field is required" }}
          />

          <InputField
            className="input-field"
            label="Email Address"
            name="email"
            inputProps={{ type: "email" }}
            rules={{ required: "This field is required" }}
          />
        </div>

        <InputWrapper label="Signature">
          <div className="signature">
            <SignatureCanvas
              ref={signatureCanvasRef}
              canvasProps={{
                width: 300,
                height: 150,
                className: "signature-canvas",
                style: { border: "1px solid #ccc", background: "#ffffff" },
              }}
            />
            <button
              className="reset-btn"
              type="button"
              onClick={clearSignature}
            >
              <img
                className="reset"
                src="/images/icons/Reset.svg"
                alt="Reset"
              />
            </button>
          </div>
        </InputWrapper>

        <div className="agreement">
          <CheckboxField
            name="terms"
            inputProps={{ required: true }}
            label={
              <>
                Agreed to the{" "}
                <Link target="_blank" to="/terms-conditions">
                  Terms & Conditions
                </Link>{" "}
                and{" "}
                <Link target="_blank" to="/shipper-agreement">
                  Shipper Agreement
                </Link>
                .
              </>
            }
          />
        </div>

        <Button type={ButtonType.filled} label="Place order" />
      </form>
    </FormProvider>
  );
};

export default AgreementForm;
