import styled from "styled-components";
import { ContainerStyle } from "../../style/Common.style";

export const ContactUsStyle = styled.main`
  .contact-us-wrapper {
    ${ContainerStyle};

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding: 80px 15px;
    align-items: center;

    @media ( max-width: 768px ) {
      grid-template-columns: 1fr;
    }
  }

  .contacts {
    ${ContainerStyle};

    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 80px;
    padding: 0 15px;

  }

  .contact-map {
    border: 0;
    
    @media(max-width: 768px) {
      width: 100%;
    }
  }

    a {
      color: #696A6D;
      font-weight: normal;
    }
`;

export const ContactItemStyle = styled.figure`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 30px;
  
  .item-content {
    max-width: 225px;
    width: 100%;
    display: grid;
    grid-gap: 5px;

  }
`;