import { InputField } from "../../common";

const Step2 = () => {
  return (
    <article className="fields-wrapper">
      <InputField
        name="make"
        label="Vehicle Make"
        rules={{ required: "This field is required" }}
      />
      <InputField
        name="model"
        label="Vehicle Model"
        rules={{ required: "This field is required" }}
      />
      <InputField
        name="year"
        label="Vehicle Year"
        inputProps={{ type: "number" }}
        rules={{ required: "This field is required" }}
      />
    </article>
  );
};

export default Step2;
