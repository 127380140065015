import ReactInputMask from "react-input-mask";
import { Controller } from "react-hook-form";

import InputWrapper from "../InputField/InputWrapper";
import { PhoneInputFieldStyle } from "./PhoneInputField.style";

interface PhoneInputFieldProps {
  name: string;
  label: string;
  className?: string;
  inputProps?: any;
  rules?: any;
}

const PhoneInputField = ({
  name,
  label,
  className,
  ...rest
}: PhoneInputFieldProps) => {
  return (
    <PhoneInputFieldStyle className={className}>
      <InputWrapper label={label} name={name}>
        <Controller
          name={name}
          render={({ field }) => (
            <ReactInputMask mask="+1 (999) 999-9999" {...field} {...rest} />
          )}
        />
      </InputWrapper>
    </PhoneInputFieldStyle>
  );
};

export default PhoneInputField;
