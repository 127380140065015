import { AboutIcon, BuildingTrustIcon, DriveBySatisfactionIcon, EchoFriendlyIcon } from "../../assets";
import { Card, Section } from "../common";
import { OurMissionStyle } from "./OurMission.style";

const OurMission = () => {
  return (
    <Section title="Our Mission">
      <OurMissionStyle>
        <Card
          icon={<AboutIcon />}
          title="About Company"
          description="Safety is our top priority on the road – just like we'd want for our own vehicles. Be easy knowing that your vehicle is in caring hands."
        />

        <Card
          icon={<EchoFriendlyIcon />}
          title="Eco-Friendly Approach"
          description="We take an environment friendly approach to auto transport, making sure your vehicle's journey is environmentally kind."
        />

        <Card
          icon={<BuildingTrustIcon />}
          title="Building Your Trust"
          description="At our auto transport company, we're focused on earning your trust through caring service and reliability. Your confidence is at the core of our journey."
        />

        <Card
          icon={<DriveBySatisfactionIcon />}
          title="Driven By Your Satisfaction"
          description="Your happiness drives us, motivating us to provide exceptional auto transport. We're dedicated to making your vehicle's journey worry free."
        />
      </OurMissionStyle>
    </Section>
  );
};

export default OurMission;
