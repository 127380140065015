import { createGlobalStyle } from "styled-components";

export const BaseStyle = createGlobalStyle`
    html {
        body {
            margin: 0;
            margin-top: 76px;
            padding: 0;
            font-size: 16px;
            
            * {
                margin: 0;
                padding: 0;
                text-decoration: none;
                box-sizing: border-box;
                font-family: "Poppins", Helvetica, Arial, sans-serif;
            }
            
            ol {
                color: #696A6D;
                
                &:marker {
                    color: #696A6D;
                }
            }

            ul {
                list-style: none;
            }

            a {
                color: #CB1E0A;
                font-size: 14px;
                font-weight: 600;

                &:hover {
                    color: #FF8500;
                }
            }

            h2 {
                font-size: 34px;
                font-weight: 700;
                color: #19222B;
            }

            h3 {
                font-size: 20px;
                font-weight: 700;
                color: #19222B;
            }

            p {
                font-size: 16px;
                font-weight: 16px;
                color: #696A6D;
            }
        }
    }
`;