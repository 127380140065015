import { css } from "styled-components";

export const ContainerSmallStyle = css`
    max-width: 720px;
    width: 100%;
    margin: auto;
`;

export const ContainerStyle = css`
    max-width: 1130px;
    width: 100%;
    margin: auto;
`;
