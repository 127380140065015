import { QuotationForm } from "../QuotationForm";
import { HeaderStyle, InnerHeaderStyle } from "./Header.style";

export enum HeaderType {
  HOME = "home",
  inner = "inner",
}

interface HeaderProps {
  type?: HeaderType;
  image: string;
  heading?: string;
  className?: string;
}

const Header = (props: HeaderProps) => {
  const { type = HeaderType.HOME, image, heading, className } = props;

  if (type === HeaderType.inner) {
    return (
      <InnerHeaderStyle image={image} className={className}>
        <h1 className="heading">{heading}</h1>
      </InnerHeaderStyle>
    );
  }

  return (
    <HeaderStyle image={image}>
      <section className="header-wrapper">
        <article className="header-left">
          <h1 className="heading">
            Fast and Reliable
            <p>Auto Transport Service</p>
          </h1>

          <p className="subheading">
            We have lots of experience moving vehicles, offering trustworthy
            transport all over the U.S. We're here to give you great service and
            peace of mind
          </p>

          {/* <Button
            label="Track Your Vehicle"
            endIcon={<TrackIcon />}
            onClick={() => {}}
          /> */}
        </article>

        <article className="header-right">
          <QuotationForm />
        </article>
      </section>
    </HeaderStyle>
  );
};

export default Header;
