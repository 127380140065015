import React from "react";

import { ButtonStyle } from "./Button.style";

export enum ButtonType {
  outlined = "outlined",
  filled = "filled",
}

type ButtonProps = {
  label: React.ReactNode;
  type?: ButtonType;
  onClick?: () => void;
  className?: string;
  endIcon?: React.ReactNode;
  disabled?: boolean;
  as?: React.ElementType;
};

const Button = (props: ButtonProps) => {
  const { label, as, type = ButtonType.outlined, endIcon, ...restProps } = props;

  return (
    <ButtonStyle as={as} type={type} {...restProps}>
      {label} {endIcon && endIcon}
    </ButtonStyle>
  )
}

export default Button;
