import { ShippingCard } from "../ShippingCard";
import { Section } from "../common";
import { SectionType } from "../common/Section/Section";
import { ShippingSectionStyle } from "./ShippingSection.style";

const ShippingSection = () => {
  return (
    <Section title="Our Shipping Process" type={SectionType.dark}>
      <ShippingSectionStyle>
        <ShippingCard
          heading="Get a Quote"
          list={[
            "Experience the personal touch in transporting your vehicle.",
            "Get a fast estimate by filling out the form or calling.",
          ]}
        />

        <ShippingCard
          heading="Place Order"
          list={[
            "Order online or engage in a friendly chat with our car shipping experts over the phone.",
            "Choose the approach that suit you best.",
          ]}
        />

        <ShippingCard
          heading="Schedule Pickup"
          list={[
            "Order online or engage in a friendly chat with our car shipping experts over the phone.",
            "Choose the approach that suit you best.",
          ]}
        />

        <ShippingCard
          heading="Move Vehicle"
          list={[
            "Caring driver will contact you before transport for a seamless journey.",
          ]}
        />
      </ShippingSectionStyle>
    </Section>
  );
};

export default ShippingSection;
