import { Section } from "../common";
import { ShipperAgreementContentStyle } from "./ShipperAgreementContent.style";

const ShipperAgreementContent = () => {
  return (
    <ShipperAgreementContentStyle>
      <Section className="testimonial-wrapper">
        <h3>Shipper Agreement</h3>
        <ol>
          <li>
            The carrier and driver jointly and separately are authorized to
            operate and transport his/her or their motor vehicle between its
            pickup location and the destination. All deposits paid are final and
            Non-refundable. While every effort will be made to confirm a driver
            for the estimated date, no guarantee of pickup or delivery date can
            be made. Every effort will made to ship the vehicle within the
            promised period but delays can occur due to carrier schedules,
            mechanical failure, and inclement weather, acts of God, among other
            unforeseen circumstances for which it can take up to 2 weeks. Isaah
            Trucking will not be responsible for any charges or liabilities
            incurred due to delay of pickup or delivery. This includes but is
            not limited to airline tickets or rental car fees. The client will
            be given the carrier’s schedule at the time of dispatch. The client
            agrees to release Isaah Trucking World from any liability and waive
            their right to sue Isaah Trucking, or their employees, officers,
            volunteers, and agents (collectively “District”) from any and all
            claims.
          </li>
          <li>
            The client agrees to not contract any other broker or carrier during
            the respective time that corresponds with their shipping option. Any
            client that is found working with another broker or carrier during
            this period, is subject to a Non-refundable deposit fee.
          </li>
          <li>
            Contracted carriers provide door-to-door transport if the truck
            driver can physically reach the pick-up and delivery addresses. If
            access to the pickup or delivery location is restricted by narrow
            streets, low-hanging trees, or tight turns, the driver may ask that
            you meet the truck at a large parking lot nearby, such as a grocery
            store.
          </li>
          <li>
            Carriers are not licensed or insured to transport any personal or
            household goods, however, we do understand that you may need to put
            some items in the vehicle. The carrier is not liable for damage
            caused to the vehicle from excessive or improper loading of personal
            items. These items must be put in the trunk and kept to a limit of
            100 lbs. Any exceptions must be previously discussed and approved by
            Isaah Trucking World. An additional fee may be assessed for personal
            items of any weight. Any misrepresentation of the personal
            belongings will result in a change of price and/or a dry run fee of
            $150 if a carrier is made to attend the scene of the pick-up and the
            shipment is different from expected. If a carrier is sent out and
            the vehicle is not ready as indicated by the shipper there will be
            an additional $75.00 rescheduling fee. Isaah Trucking must be
            notified, should the shipper be unavailable for pick up or delivery,
            the shipper must have an alternate representative take his/her place
            as a shipper.
          </li>
          <li>
            Vehicles must be tendered to the carrier in good running condition
            with no more than a half tank of fuel. The carrier will not be
            liable for damage caused by leaking fluids, freezing, exhaust
            systems, or antennas not tied down. Any claim for loss or damage
            must be noted and signed on the condition report at the time of
            delivery.
          </li>
          <li>
            Trucking damage claims are covered by carriers from $100,000 up to
            $250,000 cargo insurance per load, and a minimum of 3/4 of a million
            dollars in public liability and property damage. Any damage incurred
            to a vehicle during transport falls directly under the
            responsibility of the carrier and not Isaah Trucking. All carriers
            contracted will have insurance to cover damage caused by the driver
            or theft during transport. If damage is done, Isaah Trucking
            provides you with a full insurance packet for the carrier to file a
            claim. All claims must be noted and signed for at the time of
            delivery and submitted in writing within 15 days of delivery.
          </li>
          <li>
            If a carrier is sent out and the vehicle is not ready as indicated
            by the shipper there will be an additional $75.00 rescheduling fee.
            Isaah Trucking must be notified, should the shipper be unavailable
            for pick up or delivery, the shipper must have an alternate
            representative take his/her place as a shipper. If for any reason
            the vehicle becomes unavailable during a scheduled pick-up window,
            after an order has been placed, Isaah Trucking will not refund the
            deposit amount.
          </li>
          <li>
            The client should under no circumstances release or receive
            vehicle(s) from a carrier without an inspection report (Bill of
            Lading/BOL) regardless of the time of day or the weather conditions.
            Failure to do so may result in the client’s inability to file a
            damage claim. Carriers insurance will only process claims for
            damages due to the carrier’s negligence. Damage must be reported to
            Isaah Trucking within 24 hours of delivery. Damage must be listed on
            the BOL and signed by the driver (no exceptions). If there is damage
            during transport, the client must notate those damages on the final
            inspection report, pay the remaining balance stated on this
            agreement, and then contact the carrier’s main office as well as the
            carrier’s insurance company. Failure to notate any damage on the
            final inspection report releases the carrier of any liability and
            would result in the inability to process a damage claim. It is the
            customer’s responsibility to review the Carrier’s dispatch sheet
            confirm the customer's correct name and address and verify the
            identity of the truck driver prior to releasing the vehicle for
            transport. Isaah Trucking is an acting agent. All claims must be
            made with the carrier if any circumstances arise. Any/All damages
            are covered by the carrier’s insurance and must be claimed by the
            carrier’s insurance, not Isaah Trucking. In the condition of a lost
            or stolen vehicle, all claims must be made with Carrier’s insurance.
          </li>
          <li>
            Dispatched orders must be canceled by calling the offices of Isaah
            Trucking at (610) 785-6302 or by sending an email to
            support@isaahtrucking.com. Cancellations must be made no less than
            24 hours before the pick-up date. Cancellations of dispatched orders
            are subject to a refundable $200 fee.
          </li>
          <li>
            A $150.00 non-operational fee will be charged for all non-running
            vehicles. This will be included in the final quote received from
            Isaah Trucking. If the vehicle becomes non-operational during
            transport, this fee will be applied to the original quote.
          </li>
          <li>
            While every effort is made to dispatch the vehicle(s) as per the
            customer's requested scheduled date and the original quoted price to
            the customer due to continuous fluctuations in gas/diesel prices,
            shortage of drivers, inclement weather conditions, continuous
            inflation among many other factors along with the customer's
            requested shipping date, there is a possibility where the customer
            will be re-quoted for a new price which could have a price hike of
            up to 25% more than the original quote. The customer must agree to
            the new quote or the order will be considered cancelled.
          </li>
          <li>
            Isaah Trucking agrees to provide a carrier to transport your vehicle
            as promptly as possible under your instructions but cannot guarantee
            pick-up or delivery on a specified date. A cancellation fee of $200
            will be charged for orders canceled 7 days before the requested
            available pick-up date. Isaah Trucking does not agree to pay for
            your rental of a vehicle, nor shall it be liable for the failure of
            mechanical or operating parts of your vehicle. The shipper warrants
            that he/she will pay the price quoted due to Isaah Trucking for
            delivered vehicles and will not seek to charge back a credit card.
            This agreement and any shipment hereunder are subject to all terms
            and conditions of the carrier’s tariff and the uniform straight bill
            of lading, copies of which are available at the office of the
            carrier.
          </li>
          <li>
            This agreement shall be governed by and construed under the laws of
            the State of Texas. The parties further agree that any legal action
            arising out of this agreement must be filed in a court of
            jurisdiction, and Isaah Trucking is limited to the amount of money
            Isaah Trucking broker’s fee only. The client hereby submits to the
            jurisdiction of such courts and waives any right to jurisdiction in
            any other location. I hereby agree to the transport terms provided
            by Isaah Trucking. I authorize a small down payment to be paid to
            Isaah Trucking via a Cash App, Zelle, Venmo or check by phone or
            mail. I further understand that any remaining balance is due on
            delivery and that it must be paid in full via cash, cashier’s check,
            and money order, to the authorized transporter.
          </li>
        </ol>
      </Section>
    </ShipperAgreementContentStyle>
  );
};

export default ShipperAgreementContent;
