import styled from "styled-components";

export const QuoteStyle = styled.main`
  header {
    height: 200px;
  }
  
  .quote-form {
    max-height: 840px;
    overflow-y: auto;
    border: 1px solid #e2e6ec;
    border-right: 0;
  }

  .form-footer {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 20px 25px;
  }

  .large-input {
    min-width: 150px;
  }

  .section-wrapper {
    max-width: 1280px;
  }

  .fields-container {
    background-color: #F6F9FB;
    padding: 20px 25px;
  }

  .final-step {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fields-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px 5px;
    align-items: center;

    &.center {
      justify-content: center;
    }

    &.vehicle-details {
      align-items: flex-end;

      .icon-button {
        margin-bottom: 12px;
      }
    }

    &.no-wrap {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr auto;
    }
  }

  .icon-button {
    cursor: pointer;
    color: #ff8500;
    font-weight: bold;
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .quote-wrapper {
    display: flex;
  }
`;
