import { Controller, ControllerProps, useFormContext } from "react-hook-form";

import { CheckboxFieldStyle } from "./CheckboxField.style";

interface CheckboxFieldProps extends Omit<ControllerProps, "render"> {
  label: React.ReactNode;
  name: string;
  inputProps?: any;
}

const CheckboxField = (props: CheckboxFieldProps) => {
  const { label, name, inputProps, ...rest } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <CheckboxFieldStyle className="field-input" {...field} {...inputProps}>
          {label}
        </CheckboxFieldStyle>
      )}
      {...rest}
    />
  );
};

export default CheckboxField;
