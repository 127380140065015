import { SectionStyle } from "./Section.style";

export enum SectionType {
  dark = "dark",
  light = "light",
}

interface SectionProps {
  title?: string;
  children?: React.ReactNode;
  type?: SectionType;
  className?: string;
}

const Section = (props: SectionProps) => {
  const { title, children, type = SectionType.light, className = "" } = props;

  return (
    <SectionStyle type={type} className={className}>
      <article className="section-wrapper">
        {title && <h2 className="section-heading">{title}</h2>}

        {children}
      </article>
    </SectionStyle>
  );
};

export default Section;
