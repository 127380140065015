import { styled } from "styled-components";

import { SectionType } from "./Section";
import { ContainerStyle } from "../../../style/Common.style";

interface SectionStyleProps {
  type?: SectionType;
}

export const SectionStyle = styled.section<SectionStyleProps>`
  background: ${({type}) => type === SectionType.light ? "#fff" : "#E2E6EC" };
  
  .section-wrapper {
    ${ContainerStyle};
    
    padding: 70px 15px;

    .section-heading {
      text-align: center;
      margin-bottom: 20px;
    }
  }
`;
