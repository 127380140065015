import { Select as AntdSelect } from "antd";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";

import { InputSelectStyle } from "./InputSelect.style";
import InputWrapper from "../InputField/InputWrapper";

const { Option } = AntdSelect;

interface SelectProps extends Omit<ControllerProps, "render"> {
  label: string;
  name: string;
  options: string[];
  inputProps?: any;
}

const Select = (props: SelectProps) => {
  const { label, name, options, inputProps, ...rest } = props;

  const { control } = useFormContext();

  return (
    <InputWrapper label={label} name={name}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <InputSelectStyle
            style={{ width: "200px" }}
            onChange={onChange}
            value={value}
            {...inputProps}
          >
            {options.map((item) => (
              <Option value={item}>{item}</Option>
            ))}
          </InputSelectStyle>
        )}
        {...rest}
      />
    </InputWrapper>
  );
};

export default Select;
