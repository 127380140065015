import { Link } from "react-router-dom";
import { navItems } from "../../mockData/navItems";
import { FooterStyle } from "./Footer.style";
import { FacebookIcon, InstagramIcon, LinkedinIcon } from "../../assets";

const footerLinks = [
  ...navItems,
  // {
  //   name: "Track Your Order",
  //   link: "/",
  // },
  {
    name: "Get a Quote",
    link: "/",
  },
];

const socialLinks = [
  {
    icon: <FacebookIcon />,
    url: "https://www.facebook.com/profile.php?id=61550120846190",
  },
  {
    icon: <InstagramIcon />,
    url: "https://www.instagram.com/isaahinsta500/",
  },
  {
    icon: <LinkedinIcon />,
    url: "https://www.linkedin.com/in/isaah-trucking-962132288/",
  },
];

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterStyle>
      <article className="footer-wrapper">
        <article className="footer-links">
          {footerLinks.map((data) => {
            const { name, link } = data;
            return <Link to={link}>{name}</Link>;
          })}
        </article>

        <article className="footer-social-links">
          {socialLinks.map((data) => {
            const { icon, url } = data;

            return (
              <a href={url} rel="noreferrer" target="_blank">
                {icon}
              </a>
            );
          })}
        </article>
      </article>

      <article className="copy-right">
        Copyright © {currentYear} Isaah Trucking Inc. All rights reserved |{" "}
        <Link to="/terms-conditions">Terms & Conditions</Link> |{" "}
        <Link to="/privacy-policy">Privacy policy</Link> |{" "}
        <Link to="/disclaimer">Disclaimer</Link>
      </article>
    </FooterStyle>
  );
};

export default Footer;
