import styled from "styled-components";

export const TermsConditionsStyle = styled.main`
  .testimonial-wrapper {
    p, ul, li {
      margin-bottom: 25px;

      &.mb-0 {
        margin-bottom: 0;
      }
    }

    ol {
      list-style: decimal;
    }

    h3 {
      margin-bottom: 10px;
    }

    h4 {
      margin-bottom: 5px;
    }

    ul {
      li {
        list-style-type: disc;
        margin-left: 20px;
        color: #696A6D;
      }
    }
  }
`;