import { SectionWithVisual } from "../SectionWithVisual";
import { OurVisionStyle } from "./OurVision.style";

const OurVision = () => {
  return (
    <OurVisionStyle>
      <article className="our-vision-wrapper">
        <SectionWithVisual
          className="our-vision"
          heading="Our Vision"
          description="We do things right, no shortcuts. With us, you get
          expertise, trust, and clear practices. We care about
          your things and give our best. We're not just a
          business, we're a caring team. Choosing us means
          benefiting from our knowledge and honesty. Thanksfor
          considering us. We're here to offer you a friendly and
          reliable service that's all about you."
          image="/images/services/our-vision.svg"
        />
      </article>
    </OurVisionStyle>
  );
};

export default OurVision;
