import styled from "styled-components";

export const ShipperAgreementContentStyle = styled.main`
  .testimonial-wrapper {
    p,
    ul,
    li {
      margin-bottom: 25px;

      &.mb-0 {
        margin-bottom: 0;
      }
    }

    h3 {
      margin-bottom: 10px;
    }

    h4 {
      margin-bottom: 5px;
    }

    ol {
      list-style: decimal;
    }

    ul {
      li {
        list-style-type: disc;
        margin-left: 20px;
        color: #696a6d;
      }
    }
  }
`;
