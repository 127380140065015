import jsPDF from "jspdf";

import {
  Button,
  Footer,
  Header,
  InvoiceFormPreview,
  QuoteForm,
} from "../../components";
import { HeaderType } from "../../components/Header/Header";
import { QuoteStyle } from "./Quote.style";
import { Section } from "../../components/common";
import { FieldValues, FormProvider, set, useForm } from "react-hook-form";
import { useState } from "react";
import Preview from "../../components/QuoteForm/Preview";
import { ButtonType } from "../../components/common/Button/Button";
import axios from "axios";
import DownloadPdfCTA from "../../components/QuoteForm/DownloadPdfCTA";
import { message } from "antd";
import FinalStep from "../../components/QuotationForm/Steps/FinalStep";
import { getInvoicePreview } from "../../components/InvoiceFormPreview/InvoiceFormPreview";
import { format } from "date-fns";

const Quote = () => {
  const methods = useForm({
    defaultValues: {
      vehicles: [
        {
          vehicleYear: "",
          vehicleMake: "",
          vehicleModel: "",
          quantity: 0,
          price: 0,
          etaForDropOff: "",
        },
      ],
    },
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    handleSubmit,
    getValues,
    formState: { isDirty, isValid },
    reset,
  } = methods;

  const [vehiclesTotalPrice, setVehiclesTotalPrice] = useState(0);

  const [isPreviewMode, setPreviewMode] = useState(false);

  const togglePreviewMode = () => {
    setPreviewMode(!isPreviewMode);
  };

  const handleFormSubmit = (data: FieldValues) => {
    const day = String(new Date(data.pickUpDate).getDate()).padStart(2, "0");
    const month = String(new Date(data.pickUpDate).getMonth() + 1).padStart(
      2,
      "0"
    ); // January is 0!
    const year = new Date(data.pickUpDate).getFullYear();

    const formattedDate = `${month}-${day}-${year}`;

    const values: FieldValues = { ...data, pickUpDate: formattedDate };
    console.log({values})
    axios
      .post(
        `https://isaahtrucking.com/php/qoute.php?representativeEmail=${
          values.representativeEmail
        }&representativeName=${values.representativeName}&representativePhone=${
          values.representativePhone
        }&billToName=${values.billToName}&billToPhone=${
          values.billToPhone
        }&billToEmail=${values.billToEmail}&billFromName=${
          values.billFromName
        }&billFromPhone=${values.billFromPhone}&pickUpDate=${format(new Date(values.pickUpDate), 'MM/dd/yyyy')}&etaForPickup=${
          values.etaForPickup
        }&pickupFrom=${values.pickupFrom}&deliveryTo=${
          values.deliveryTo
        }&totalAmount=${vehiclesTotalPrice}&deposit=${values.deposit}&balance=${
          values.balance
        }&vehicles=${JSON.stringify(values.vehicles)}&
        pickupFullAddress=${encodeURIComponent(values.pickupFullAddress)}&
        dropOffFullAddress=${encodeURIComponent(values.dropOffFullAddress)}&
        dropOffPersonPhone=${values.dropOffPersonPhone}&
        dropOffPersonName=${values.dropOffPersonName}&
        dropOffPersonEmail=${values.dropOffPersonEmail}&
        etaForDropOff=${values.etaForDropOff}
        `
      )
      .then((response) => {
        message.success("Email sent successfully");
        console.log({ response });
        setIsSubmitted(true);
        reset();
      });
  };

  const handleCopy = () => {
    if (!isDirty || !isValid) {
      message.error("Please fill the form before copying");
      return;
    }

    if (!window) {
      return;
    }

    const div = document.getElementById("myTable");
  if (div) {
    const html = div.outerHTML;
    navigator.clipboard.writeText(html).then(function() {
      console.log('Copying to clipboard was successful!');
    }, function(err) {
      console.error('Could not copy text: ', err);
    });
  } else {
    console.error('Could not find div with id: ', "myTable");
  }

    // const values = getValues();

    // navigator.clipboard
    //   .writeText(getInvoicePreview(values, vehiclesTotalPrice))
    //   .then(
    //     function () {
    //       console.log("Copying to clipboard was successful!");
    //       message.success("Copied to clipboard");
    //     },
    //     function (err) {
    //       console.error("Could not copy text: ", err);
    //       message.error("Could not copy text");
    //     }
    //   );
  };

  return (
    <QuoteStyle>
      <Header
        heading="Invoice"
        image="/images/banners/terms-and-conditions.png"
        type={HeaderType.inner}
      />
      <Section>
        {isSubmitted ? (
          <div className="final-step">
            <FinalStep />
            <Button label="Go Back" onClick={() => setIsSubmitted(false)} />
          </div>
        ) : (
          <FormProvider {...methods}>
            <section className="quote-wrapper">
              <form
                className="quote-form"
                onSubmit={handleSubmit(handleFormSubmit)}
              >
                <article className="fields-container">
                  <QuoteForm
                    vehiclesTotalPrice={vehiclesTotalPrice}
                    setVehiclesTotalPrice={setVehiclesTotalPrice}
                  />
                </article>

                <article
                  className={`fields-wrapper form-footer ${isPreviewMode && "center"}`}
                >
                  <Button
                    type={ButtonType.filled}
                    label="Send Email"
                    disabled={!isDirty || !isValid}
                  />
                  {/* {!isPreviewMode && (
                    <Button
                      onClick={togglePreviewMode}
                      type={ButtonType.outlined}
                      label="Preview"
                      disabled={!isDirty || !isValid}
                    />
                  )} */}
                  {/* <DownloadPdfCTA /> */}
                  {/* {isPreviewMode && (
                    <Button label="Go Back" onClick={togglePreviewMode} />
                  )} */}
                  {/* <Button
                    as="div"
                    type={ButtonType.outlined}
                    label="Copy"
                    disabled={!isDirty || !isValid}
                    onClick={handleCopy}
                  /> */}
                </article>
              </form>

              <InvoiceFormPreview vehiclesTotalPrice={vehiclesTotalPrice} />
            </section>
          </FormProvider>
        )}
      </Section>

      <Footer />
    </QuoteStyle>
  );
};

export default Quote;
