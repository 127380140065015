import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { About, Agreement, ContactUs, Disclaimer, Home, Quote, Services, ShipperAgreement } from "./pages";
import { Navbar, ScrollToTop } from "./components";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import { BaseStyle } from "./style/Base.style";

function App() {
  return (
    <div className="App">
      <BaseStyle />

      <BrowserRouter>
        <Navbar logo="/images/logo.png" />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/shipper-agreement" element={<ShipperAgreement />} />
          <Route path="/invoice" element={<Quote />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
