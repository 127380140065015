import {
  CustomerCareIcon,
  EffortlessShippingIcon,
  VerifiedIcon,
} from "../../assets";
import { Card, Section } from "../common";
import { WhyChooseUsStyle } from "./WhyChooseUs.style";

const WhyChooseUs = () => {
  return (
    <Section title="Why Choose Us?">
      <WhyChooseUsStyle>
        <Card
          isCentered
          icon={<EffortlessShippingIcon />}
          title="Effortless Shipping"
          description="Experience the ease of shipping with our seamless auto transport services."
        />

        <Card
          isCentered
          icon={<VerifiedIcon />}
          title="Verified Trucks & Drivers"
          description="Your vehicle is safe with us – our verified trucks and skilled drivers take good care during transport."
        />

        <Card
          isCentered
          icon={<CustomerCareIcon />}
          title="24/7 Customer Care"
          description="We're here for you around the clock with 24/7 customer support for all your auto transport needs."
        />
      </WhyChooseUsStyle>
    </Section>
  );
};

export default WhyChooseUs;
