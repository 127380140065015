import { Footer, Header } from "../../components";
import { HeaderType } from "../../components/Header/Header";
import { Section } from "../../components/common";
import { TermsConditionsStyle } from "./TermsConditions.style";

const TermsConditions = () => {
  return (
    <TermsConditionsStyle>
      <Header
        heading="Terms & Conditions"
        image="/images/banners/terms-and-conditions.png"
        type={HeaderType.inner}
      />

      <Section className="testimonial-wrapper">
      <h3>Agreement Overview and Booking Terms</h3>
      <p>
          This Agreement between the Customer named above (hereinafter referred to as the "Customer")
          and   Isaah Trucking Ltd (hereinafter referred to as "  Isaah Trucking ") designates only  
          Isaah Trucking to act as the booking agent to attempt reservation with a transport car carrier
          (hereinafter referred to as the "Carrier"). For the purposes of this agreement, "vehicle"
          refers to all items being shipped by the Carrier through Isaah Trucking on behalf of the
          Customer, including but not limited to single vehicles, multiple vehicles, and freight.
          The Customer acknowledges that   Isaah Trucking pre-negotiates certain rates with carriers
          to facilitate the booking of reservations on the Customer’s behalf. The Customer also
          acknowledges that Isaah Trucking provides services to facilitate such booking of reservations
          for a consideration (the "booking fee"). The total rate displayed (the "reservation fee") on
          the quote is a combination of the pre-negotiated Carrier rate on behalf of the Customer by
          Isaah Trucking and the booking fee retained by Isaah Trucking for their services.
          All prices shown are in U.S. dollars. The Customer agrees that their credit card will be
          charged by Isaah Trucking for either the booking fee or total reservation price as
          specified in the Payment Disclosure Agreement. As the Customer's agent, Isaah Trucking is
          hired to attempt to book a reservation with a Carrier that maintains insurance, and the
          Customer acknowledges and agrees that   Isaah Trucking may rely solely on Certificates of
          Insurance provided by the Carrier to determine the existence of said insurance. Isaah Trucking
          will not be held liable by the Customer for any invalidity of any documents presented to them
          by the Carrier. The Customer agrees and acknowledges that the duties of Isaah Trucking are
          solely limited to these duties, and the Customer agrees that in no event shall Isaah Trucking
          have any responsibility or liability for the inspection, loading, transport, delivery, or
          unloading of the vehicle/item. If the vehicle being shipped is valued higher than the market
          value for any  reason, Isaah Trucking highly suggests that the Customer acquire a special
          insurance rider and/or opt for enclosed transport.
      </p>

      <h3>Exclusive Agreement Terms</h3>
      <p>
        The client agrees to not contract any other broker or carrier during the respective time that
        corresponds with their shipping option. Any client that is found working with another broker
        or carrier during this period, is subject to a Non-refundable deposit fee.
      </p>

      <h3>Door-to-Door Transport Guidelines</h3>
      <p>
        Contracted carriers provide door-to-door transport if the truck driver can physically reach
        the pick-up and delivery addresses. If access to the pickup or delivery location is restricted
        by narrow streets, low-hanging trees, or tight turns, the driver may ask that you meet the
        truck at a large parking lot nearby, such as a grocery store.
      </p>

      <h3>Personal Items and Loading Guidelines</h3>
      <p>
        Isaah Trucking and the Carrier will not be held responsible for any personal or household items
        that are shipped in the vehicle during transport. These items are not covered by the insurance
        of Isaah Trucking or the Carrier under any circumstance. Should the Carrier require additional
        fees for personal items that were left in the vehicle at the time of pickup, the Customer agree
        to pay any additional fees that the Carrier requires. Isaah Trucking must be made aware upfront
        if any personal items are to be shipped in the vehicle, and the Carrier and Isaah Trucking reserve
        the right to deny the customer the privilege of leaving personal items in the vehicle for any
        reason, regardless of additional money offered by the customer. The customer also agrees to
        follow any guidelines set by Isaah Trucking or the Carrier about the content of any items being
        transported in the vehicle (i.e., no firearms, illicit substances, explosive or flammable items,
        etc.), and the customer accepts full responsibility for any legal recourse due to any items that
        they transport in their vehicle unbeknownst to Isaah Trucking or the Carrier.
      </p>

      <h3>Vehicle Condition and Liability</h3>
      <p>
        Vehicles must be tendered to the carrier in good running condition with no more than a half tank
        of fuel. The carrier will not be liable for damage caused by leaking fluids, freezing, exhaust
        systems, or antennas not tied down. Any claim for loss or damage must be noted and signed on the
        condition report at the time of delivery.
      </p>

      <h3>Insurance Coverage and Claims Process</h3>
      <p>
        Trucking damage claims are covered by carriers from $100,000 up to $250,000 cargo insurance per
        load, and a minimum of 3/4 of a million dollars in public liability and property damage. Any
        damage incurred to a vehicle during transport falls directly under the responsibility of the
        carrier and not Isaah Trucking. All carriers contracted will have insurance to cover damage
        caused by the driver or theft during transport. If damage is done, Isaah Trucking provides you
        with a full insurance packet for the carrier to file a claim. All claims must be noted and signed
        for at the time of delivery and submitted in writing within 15 days of delivery.
      </p>

      <h3>Rescheduling and Additional Fees</h3>
      <p>
        The Carrier, and/or Isaah Trucking , will notify the Customer prior to pick-up and/or delivery
        within 3 hours or more of the scheduled pick-up time (“ample notice”). It is the Customer's
        responsibility to have any payment due when the Carrier arrives in the designated payment method,
        generally cash or other certified funds. In order to facilitate pickup and delivery, the Customer
        agrees to meet the Carrier at any specified time and place without exception. In the Customer's
        absence, they shall designate a person over 18 years of age to act as their agent at the point
        of pick-up and/or delivery. The designated agent must be identified to Isaah Trucking before the
        Carrier arrives at pickup or delivery for the safety and protection of the Carrier and the vehicle.
        The Customer agrees that any delays occasioned by their failure to release/receive the vehicle
        within ample notice of the scheduled pick-up time will be subject to a rescheduling fee of $75.00.
      </p>

      <h3>Vehicle Release and Inspection Procedures</h3>
      <p>
        The client should under no circumstances release or receive vehicles from a carrier without an
        inspection report (Bill of Lading/BOL), regardless of the time of day or the weather conditions.
        Failure to do so may result in the client’s inability to file a damage claim. Carriers' insurance
        will only process claims for damages due to the carrier’s negligence. Damage must be reported to
        Isaah Trucking within 24 hours of delivery. Damage must be listed on the BOL and signed by the
        driver (no exceptions). If there is damage during transport, the client must notate those damages
        on the final inspection report, pay the remaining balance stated on this agreement, and then
        contact the carrier’s main office as well as the carrier’s insurance company. Failure to notate
        any damage on the final inspection report relieves the carrier of any liability and would result
        in the inability to process a damage claim. It is the customer’s responsibility to review the
        Carrier’s dispatch sheet, confirm the customer's correct name and address, and verify the identity
        of the truck driver prior to releasing the vehicle for transport. Isaah Trucking is an acting agent.
        All claims must be made with the carrier if any circumstances arise. Any and all damages are covered
        by the carrier’s insurance.
      </p>

      <h3>Refunds and Cancellation</h3>
      <p>
        In order to cancel a booking, all requests must be made in writing via electronic transmission
        or mail. If a Customer cancels an order within 24 hours of placing the order, Isaah Trucking will
        refund the Customer all monies due minus any fees incurred to   Isaah Trucking during that time if
        applicable. All domestic booking requests excluding ocean shipments in effect longer than 24 hours
        without reservation can be canceled but are subject to an administrative fee of $75.00. All
        bookings with reservations, regardless of dates, will be unable to be canceled and therefore
        non-refundable. In the event of the Customer’s refusal for release of vehicle/item for Carrier,
        the Customer will forfeit the right to any refund. If a Customer cancels any International and/or
        Ocean booking request after 24 hours of initial booking, order cancellations are subject to an
        administrative fee of 15% of the total cost paid. No refund will be issued for more than the
        deposit that Isaah Trucking charged on dispatch for any reason."
      </p>

      <h3>Pricing Adjustments and Re-Quoting</h3>
      <p>
        The Customer acknowledges that, due to the instability of the current price of diesel fuel,
        there may be a price increase to the original quote. In the event that the price is increased,
        Isaah Trucking will contact the Customer first to advise them of the increased cost. Agreeing to
        this increased price will ensure that the vehicle will be transported in a timely manner. However,
        the Customer reserves the right to refuse a price increase. In the event of a refusal from the
        Customer, Isaah Trucking will continue looking for a carrier at the original quoted price. Should
        the Customer choose this option, they agree and understand that it will most likely take longer
        than originally anticipated to dispatch the vehicle. Should the Customer choose to cancel their
        order rather than accept or refuse the price increase, the Customer will still be held financially
        responsible for any and all fees related to the cancellation as outlined in section 18 of this
        agreement.
      </p>

      <h3>Shipping Terms and Guarantees</h3>
      <p>
        Isaah Trucking does not agree to arrange shipment in time for any particular market or event and
        will not be responsible for loss or damages occasioned by delays of any kind or for any reason.
        Vehicle/item rentals will not be reimbursed except if it falls under the category of a reimbursed
        rental, which is outlined in section 21. No pick-up and/or delivery dates/times are fully
        guaranteed. First available dates are estimates and not guaranteed windows for booking/scheduling;
        they are only the first set of dates for availability. Delays may occur prior to and/or during
        transport. Customers are required to allow an additional 5 business days after the first available
        dates for reservation in the event of any blackout dates and/or over-booked Carriers. In the event
        of any blackout dates or over-booked Carriers, Isaah Trucking 's cancellation terms and refund
        policies will remain in effect. Additional fees charged for any non-honored guaranteed date/time
        will be refunded.
      </p>
      
      <h3>Legal Jurisdiction and Agreement</h3>
      <p>
        This agreement shall be governed by and construed under the laws of the State of Pennsylvania.
        The parties further agree that any legal action arising out of this agreement must be filed in a
        court of jurisdiction, and Isaah Trucking is limited to the amount of money Isaah Trucking
        broker’s fee only. The client hereby submits to the jurisdiction of such courts and waives any
        right to jurisdiction in any other location. I hereby agree to the transport terms provided by
        Isaah Trucking. I authorize a small down payment to be paid to   Isaah Trucking via a Cash App,
        Zelle, Venmo, or check by phone or mail. I further understand that any remaining balance is due
        on delivery and that it must be paid in full via cash, cashier’s check, and money order, to the
        authorized transporter.
      </p>

      </Section>
      <Footer />
    </TermsConditionsStyle>
  );
};

export default TermsConditions;
