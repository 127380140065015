import { styled } from "styled-components";

interface CardStyleProps {
  isCentered?: boolean;
};

export const CardStyle = styled.figure<CardStyleProps>`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
  transition: all 200ms linear;
  text-align: ${({ isCentered }) => isCentered ? "center" : "left"};
  align-items: ${({ isCentered }) => isCentered ? "center" : "left"};

  &:hover {
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.30);
  }
`;