import styled from "styled-components";

export const PhoneInputFieldStyle = styled.div`
  .PhoneInputCountry {
    display: none;
  }

  input {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;

    &:focus {
      border-color: #ff9f29;
      outline: none;
    }
  }
`;
