import { ContactIcon, EmailIcon, LocationIcon } from "../../assets";
import { ContactUsForm, Footer, Header } from "../../components";
import { HeaderType } from "../../components/Header/Header";
import { ContactItemStyle, ContactUsStyle } from "./ContactUs.style";

const ContactItem = ({
  icon,
  content,
}: {
  icon: React.ReactNode;
  content: React.ReactNode;
}) => {
  return (
    <ContactItemStyle>
      {icon}
      <p className="item-content">{content}</p>
    </ContactItemStyle>
  );
};

const ContactUs = () => {
  return (
    <>
      <ContactUsStyle>
        <Header
          heading="Contact Us"
          image="/images/banners/contact-us.png"
          type={HeaderType.inner}
        />

        <article className="contact-us-wrapper">
          <section>
            <ContactUsForm />
          </section>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3054.3071404809916!2d-75.4099616726669!3d40.046246197795966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c694c3e25eea43%3A0xbb5d37af24ed29c5!2s625%20W%20Lancaster%20Ave%2C%20Wayne%2C%20PA%2019087!5e0!3m2!1sen!2sus!4v1713793227978!5m2!1sen!2sus"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Isaah Trucking Map"
          />
        </article>

        <section className="contacts">
          <ContactItem
            icon={<ContactIcon />}
            content={<a href="tel:+1(610) 785-6302">+1(610) 785-6302</a>}
          />

          <ContactItem
            icon={<EmailIcon />}
            content={
              <a href="contact@isaahtrucking.com">contact@isaahtrucking.com</a>
            }
          />

          <ContactItem
            icon={<LocationIcon />}
            content="625 Lancaster Ave, Wayne, PA 19087"
          />
        </section>
      </ContactUsStyle>
      <Footer />
    </>
  );
};

export default ContactUs;
