import { Controller, ControllerProps, useFormContext } from "react-hook-form";
import InputWrapper from "../InputField/InputWrapper";
import { TextAreaFieldStyle } from "./TextAreaField.style";

interface TextAreaFieldProps extends Omit<ControllerProps, "render"> {
  label: string;
  name: string;
  fullWidth?: boolean;
}

const TextAreaField = (props: TextAreaFieldProps) => {
  const { label, name, fullWidth, ...rest } = props;

  const { control } = useFormContext();

  return (
    <InputWrapper
      label={label}
      name={name}
      className={fullWidth ? "full-width" : ""}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextAreaFieldStyle className="field-input" {...field} />
        )}
        {...rest}
      />
    </InputWrapper>
  );
};

export default TextAreaField;
