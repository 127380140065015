import { useFieldArray, useFormContext } from "react-hook-form";

import { InputField } from "../../components/common";
import { DeleteIcon } from "../../assets";
import Select from "../common/InputSelect/Select";
import { useEffect } from "react";

const VehicleDetails = ({
  setVehiclesTotalPrice,
}: {
  setVehiclesTotalPrice: (_: number) => void;
}) => {
  const { control, watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "vehicles",
  });

  const values = watch();

  // console.log({vehiclesWatch, fields, vehicles});

  useEffect(() => {
    const vehicles = values.vehicles;
    const totalPrice = vehicles.reduce((acc: any, vehicle: any) => {
      return acc + Number(vehicle.price) * Number(vehicle.quantity);
    }, 0);
    setVehiclesTotalPrice(totalPrice);
    // setValue('totalPrice', totalPrice)
    console.log({ vehicles, totalPrice });
  }, [values]);

  return (
    <>
      <h3>Vehicle Details</h3>

      {fields.map((item, index) => (
        <article key={item.id} className="fields-wrapper vehicle-details no-wrap">
          <InputField
            name={`vehicles[${index}].vehicleYear`}
            label="Year"
            inputProps={{ type: "number" }}
            rules={{ required: "This field is required" }}
          />

          <InputField
            name={`vehicles[${index}].vehicleMake`}
            label="Make"
            className="large-input"
            rules={{ required: "This field is required" }}
          />

          <InputField
            name={`vehicles[${index}].vehicleModel`}
            label="Model"
            className="large-input"
            rules={{ required: "This field is required" }}
          />

          <InputField
            name={`vehicles[${index}].quantity`}
            label="QTY"
            inputProps={{ type: "number" }}
            rules={{ required: "This field is required" }}
          />

          <InputField
            name={`vehicles[${index}].price`}
            label="Price"
            inputProps={{ type: "number" }}
            rules={{ required: "This field is required" }}
          />

          <span
            className="icon-button"
            onClick={() => fields.length > 1 && remove(index)}
          >
            <DeleteIcon />
          </span>
        </article>
      ))}
      <span
        className="icon-button"
        onClick={() =>
          append({
            vehicleMake: "",
            vehicleModel: "",
            vehicleYear: "",
            quantity: "",
            price: "",
          })
        }
      >
        + Add Vehicle
      </span>
    </>
  );
};

export default VehicleDetails;
