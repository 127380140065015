import { AuthorityIcon, ExpertiseIcon, TransparencyIcon } from "../../assets";
import { Card, Section } from "../common";
import { SectionType } from "../common/Section/Section";
import { OurCoreValuesStyle } from "./OurCoreValues.style";

const OurCoreValues = () => {
  return (
    <Section title="Our Core Values" type={SectionType.dark}>
      <OurCoreValuesStyle>
        <Card
          isCentered
          icon={<AuthorityIcon />}
          title="Authority"
          description="Over the years, we have gained extensive experience in this field. Our expertise enables us to understand what strategies are effective and how to help you meet your deadlines efficiently."
        />

        <Card
          isCentered
          icon={<ExpertiseIcon />}
          title="Expertise"
          description="Shipping can be a complex and bureaucratic process. With our many years of experience, we can assist you in navigating even the most challenging situations."
        />

        <Card
          isCentered
          icon={<TransparencyIcon />}
          title="Transparency"
          description="We take pride in our commitment to transparency, especially in an industry known for its untrustworthy companies Our customers are always well-informed, and we offer competitive quotes."
        />
      </OurCoreValuesStyle>
    </Section>
  );
};

export default OurCoreValues;
