import { useFormContext } from "react-hook-form";
import { InputDate, InputField, InputSelect, PhoneInputField } from "../common";
import Select from "../common/InputSelect/Select";
import { TextAreaField } from "../common/TextAreaField";
import VehicleDetails from "./VehicleDetails";
import { useEffect } from "react";
import PaymentDetails from "./PaymentDetails";

const QuoteForm = ({
  vehiclesTotalPrice,
  setVehiclesTotalPrice,
}: {
  vehiclesTotalPrice: number;
  setVehiclesTotalPrice: (_: number) => void;
}) => {
  const { watch, setValue } = useFormContext();
  const depositWatch = watch("deposit");

  useEffect(() => {
    const balance = vehiclesTotalPrice - depositWatch;
    setValue("balance", balance);
  }, [depositWatch, vehiclesTotalPrice]);
  return (
    <>
      <h3>Representative Details</h3>

      <article className="fields-wrapper">
        <InputField
          name="representativeName"
          label="Name"
          rules={{ required: "This field is required" }}
        />
        <PhoneInputField
          name="representativePhone"
          label="Phone"
          rules={{ required: "This field is required" }}
        />
        <InputField
          name="representativeEmail"
          label="Email"
          inputProps={{ type: "email" }}
          rules={{ required: "This field is required" }}
        />
      </article>

      <h3>Bill To</h3>
      <article className="fields-wrapper">
        <InputField
          name="billToName"
          label="Name"
          rules={{ required: "This field is required" }}
        />
        <PhoneInputField
          name="billToPhone"
          label="Phone"
          rules={{ required: "This field is required" }}
        />
        <InputField
          name="billToEmail"
          label="Email"
          inputProps={{ type: "email" }}
          rules={{ required: "This field is required" }}
        />
      </article>

      <h3>Pickup Details</h3>
      <article className="fields-wrapper">
        <InputField
          name="billFromName"
          label="Pickup Person Name"
          rules={{ required: "This field is required" }}
        />

        <PhoneInputField
          name="billFromPhone"
          label="Phone"
          rules={{ required: "This field is required" }}
        />

        <InputSelect name="pickupFrom" label="Pickup From" />

        <InputDate
          name="pickUpDate"
          label="Pickup Date"
          rules={{ required: "This field is required" }}
        />

        <Select
          name="etaForPickup"
          label="ETA for Pickup"
          inputProps={{ style: { minWidth: "200px" } }}
          options={[
            "Early Morning",
            "Morning",
            "Afternoon ",
            "Late Afternoon",
            "Evening",
            "Late Evening",
            "Midnight",
          ]}
          rules={{ required: "This field is required" }}
        />

        <TextAreaField
          name="pickupFullAddress"
          label="Full Address"
          fullWidth
        />
      </article>

      <h3>Dropoff Details</h3>
      <article className="fields-wrapper">
        <InputField
          name="dropOffPersonName"
          label="Name"
          rules={{ required: "This field is required" }}
        />

        <PhoneInputField
          name="dropOffPersonPhone"
          label="Phone"
          rules={{ required: "This field is required" }}
        />

        <div />

        <InputSelect name="deliveryTo" label="Dropoff To" />
        <Select
          name={"etaForDropOff"}
          label="ETA for Drop-off"
          inputProps={{ style: { minWidth: "200px" } }}
          options={[
            "Same Day",
            "Next Day",
            "1-2 Days",
            "2-3 Days",
            "3-4 Days",
            "4-5 Days",
            "5-6 Days",
            "6-7 Days",
            "7-8 Days",
          ]}
          rules={{ required: "This field is required" }}
        />

        <TextAreaField
          name="dropOffFullAddress"
          label="Full Address"
          fullWidth
        />
      </article>

      <VehicleDetails setVehiclesTotalPrice={setVehiclesTotalPrice} />

      <PaymentDetails totalAmount={vehiclesTotalPrice} />
    </>
  );
};

export default QuoteForm;
