import { Footer, Header, SectionWithVisual } from "../../components";
import { HeaderType } from "../../components/Header/Header";
import { SectionWithVisualType } from "../../components/SectionWithVisual/SectionWithVisual";
import { SectionType } from "../../components/common/Section/Section";
import { ServicesStyle } from "./Services.style";

const Services = () => {
  return (
    <ServicesStyle>
      <Header
        type={HeaderType.inner}
        heading="Services"
        image="/images/banners/services.png"
      />

      <SectionWithVisual
        heading="Door to Door Shipping"
        description="With our door-to-door auto shipping, we're bringing the ease of having your vehicle delivered straight to your home. No more terminal fuss, just your car's safe journey to you.Bid farewell to the headache of terminal coordination. Our door-to-door service empowers you to dictate the journey, ensuring your vehicle arrives at its destination on your timeline.Let us handle the nitty-gritty as we guide your vehicle right to your designated spot. "
        image="/images/services/door-to-door-shipping.png"
      />

      <SectionWithVisual
        heading="Instant Booking"
        description="Experience the thrill of booking your vehicle transport on your terms with our Instant Booking feature – no waiting, just a smooth ride ahead. Say goodbye to delays. With Instant Booking, get an instant quote, confirm with a click, and enjoy peace of mind as your car's journey begins promptly.Take the wheel of your transport experience. Instant Booking puts you in control, ensuring your car's adventure starts exactly when you're ready."
        image="/images/services/instant-booking.png"
        type={SectionWithVisualType.right}
        sectionType={SectionType.dark}
      />

      <SectionWithVisual
        heading="Instant Quote"
        description="Instant Booking: Making auto transportation simpler at ISAAH Trucking. Get prices that are all-inclusive and upfront in a matter of minutes. Just convenience and tranquility, no more hidden fees. Your car's journey can be easily planned using Instant Booking.We're committed to revolutionizing your experience and making the process smoother than ever before. Your time is valuable, and we're here to save it."
        image="/images/services/instant-quote.png"
      />

      <SectionWithVisual
        heading="Safe & Secured Delivery"
        description="Your car will be handled by ISAAH Trucking's skilled professionals, ensuring its security the entire way. We put your vehicle's protection first at every stage, from cutting-edge technology to secure carriers. We put your vehicle's protection first at every stage, from cutting-edge technology to secure carriers."
        image="/images/services/safe-secured.png"
        type={SectionWithVisualType.right}
        sectionType={SectionType.dark}
      />

      <SectionWithVisual
        heading="Track Your Vehicle"
        description="Increasing Transparency to Track Your Vehicle with Ease. Transparency and mental tranquility are top priorities at our business. You can easily track your vehicle's journey in real-time with the help of our reliable vehicle tracking system, learning more about its precise location and anticipated arrival. Reiterating our dedication to providing you with a seamless and dependable experience, stay informed and connected throughout the transportation process. Your trust, and we've made tracking with our technology simple."
        image="/images/services/tracking-illustration.png"
      />

      <SectionWithVisual
        heading="Premium Towing Service"
        description="With our company's Premium Towing Service, you can improve your auto transportation experience. We make sure your car travels safely, securely, and specifically to your needs. The highest level of care throughout the journey is ensured by our skilled team, cutting-edge machinery, and white-glove treatment. Every mile will be a seamless combination of luxury and dependability for you. Our top priority with Premium Towing is your car."
        image="/images/services/premium-towing.png"
        type={SectionWithVisualType.right}
        sectionType={SectionType.dark}
      />
      
      <Footer />
    </ServicesStyle>
  );
};

export default Services;
