import { InputField } from "../common";
import { PaymentDetailsStyle } from "./style";

interface PaymentDetailsProps {
  totalAmount: number;
}

const LabelWithChildren = ({
  label,
  children,
}: {
  label: string;
  children: any;
}) => {
  return (
    <div className="label-with-children-wrapper">
      <label>{label}</label>
      <div className="content">{children}</div>
    </div>
  );
};

const PaymentDetails = (props: PaymentDetailsProps) => {
  const { totalAmount } = props;

  return (
    <PaymentDetailsStyle>
      <h3>Payment Details</h3>
      <article className="fields-wrapper">
        <LabelWithChildren label="Total">
          <span className="total-amount">${totalAmount}</span>
        </LabelWithChildren>

        <LabelWithChildren label="Deposit">
          <InputField
            name="deposit"
            label="Deposit"
            inputProps={{ type: "number" }}
            rules={{ required: "This field is required" }}
          />
        </LabelWithChildren>

        <LabelWithChildren label="Balance">
          <InputField
            name="balance"
            label="Balance"
            inputProps={{ type: "number", disabled: true }}
            rules={{ required: "This field is required" }}
          />
        </LabelWithChildren>
      </article>
    </PaymentDetailsStyle>
  );
};

export default PaymentDetails;
