import { Radio } from "antd";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";

import { RadioFieldStyle } from "./RadioField.style";
import InputWrapper from "../InputField/InputWrapper";

interface RadioOptions {
  label: string;
  value: string;
}

interface RadioFieldProps extends Omit<ControllerProps, "render"> {
  label: string;
  name: string;
  options: RadioOptions[];
}

const RadioField = (props: RadioFieldProps) => {
  const { label, name, options, ...rest } = props;

  const { control } = useFormContext();

  return (
    <InputWrapper label={label} name={name}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Radio.Group {...field}>
            {options.map((options) => {
              return (
                <RadioFieldStyle key={options.value} value={options.value}>
                  {options.label}
                </RadioFieldStyle>
              );
            })}
          </Radio.Group>
        )}
        {...rest}
      />
    </InputWrapper>
  );
};

export default RadioField;
