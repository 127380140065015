import { Select } from "antd";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";

import { InputSelectStyle } from "./InputSelect.style";
import { citiesWithZipCode } from "../../../mockData/citiesWithZipCode";
import InputWrapper from "../InputField/InputWrapper";
import { InputField } from "../InputField";

const { Option } = Select;

interface InputSelectProps extends Omit<ControllerProps, "render"> {
  label: string;
  name: string;
}

const InputSelect = (props: InputSelectProps) => {
  const { name, ...rest } = props;

  const { control } = useFormContext();

  return <InputField inputProps={{placeholder: 'Zip code - City, State'}} name={name} {...rest} />;
};

export default InputSelect;
